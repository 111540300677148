import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "./Menu";
import { useHistory } from "react-router-dom";

export default function DefaultAppBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setIsMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Cryptos
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => history.push("/profile")}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu open={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
}
