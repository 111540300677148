// DUCKS pattern
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../../../types";
import { loadUserData } from "../../../api/user/persistUser";

const initialState: UserData = {
  usename: "",
  email: "",
  accessToken: "",
  public: false,
  friends: [],
  loggedIn: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: loadUserData() || initialState,
  reducers: {
    login(state: UserData, action: PayloadAction<UserData>) {
      return (state = { ...action.payload, loggedIn: true });
    },
    logout(state: UserData) {
      return (state = initialState);
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
