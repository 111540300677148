import { UserData } from "../../types";

const fetchLogin = async (
  email: string,
  password: string
): Promise<UserData> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Math.floor(Math.random() * 10) >= 3)
        resolve({
          usename: email.split("@")[0],
          email: email,
          accessToken: "umatokenqualquer",
          public: true,
          friends: [],
          loggedIn: true,
        });
      else reject("Invalid credentials");
    }, 1000);
  });
};

export default fetchLogin;
