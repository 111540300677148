import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

import Login from "../pages/Login/Login";
import Home from "../pages/Home/Home";
import Profile from "../pages/User/Profile";

import Main from "../pages/Main/Main";

const PrivateRoute = ({ children, ...rest }) => {
  const loggedIn = useAppSelector((state) => state.user.loggedIn);
  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn ? (
          <Main>{children}</Main>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/" exact>
          <Home />
        </PrivateRoute>
        <PrivateRoute path="/profile">
          <Profile />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
