import { UserData } from "../../types";

export const storeUserData = (user: UserData) => {
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const loadUserData = (): UserData | undefined => {
  const user = sessionStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return;
};

export const removeUserData = () => {
  sessionStorage.removeItem("user");
};
