import React from "react";
import DefaultAppBar from "./DefaultAppBar";
import Box from "@mui/material/Box";
const Main = ({ children }) => {
  return (
    <div>
      <DefaultAppBar />
      <Box p={2}>{children}</Box>
    </div>
  );
};

export default Main;
