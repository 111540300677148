import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import fetchLogin from "../../api/user/fetchLogin";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { login } from "../../redux/features/user/user";
import { Redirect } from "react-router-dom";
import { storeUserData } from "../../api/user/persistUser";

const theme = createTheme();

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const loggedIn = useAppSelector((state) => state.user.loggedIn);

  const dispatch = useAppDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);
    fetchLogin(data.get("email"), data.get("password"))
      .then((response) => {
        storeUserData(response);
        setLoading(false);
        dispatch(login(response));
      })
      .catch((error) => {
        alert(`Erro ao fazer login: ${error}`);
        setLoading(false);
      });
  };

  console.log(loggedIn);

  return (
    <>
      {loggedIn && <Redirect to="/" />}
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sistema de gestão de NFTs
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                disabled={loading}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                disabled={loading}
              />
              <FormControlLabel
                disabled={loading}
                control={<Checkbox value="remember" color="primary" />}
                label="Lembrar-me (Nem funciona ainda kk)"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => alert("Se fudeu kk")}
                  >
                    Esqueceu a senha?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => alert("Me pede uma conta.")}
                  >
                    {"Cadastrar-se"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
