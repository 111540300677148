import React from "react";
import { Button } from "@mui/material";
import { useAppDispatch } from "../../redux/hooks";
import { logout } from "../../redux/features/user/user";
import { removeUserData } from "../../api/user/persistUser";

const User = () => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    removeUserData();
    dispatch(logout());
  };

  return (
    <div>
      This is the user page :O
      <Button onClick={handleLogout}>Sair</Button>
    </div>
  );
};

export default User;
